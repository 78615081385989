import clsx from 'clsx';
import { SyntheticEvent, useState } from 'react';

interface DropdownProps {
  children: React.ReactNode;
  header: React.ReactNode;
}

export const Dropdown: React.FC<DropdownProps> = ({ children, header }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="relative inline-block text-left"
      onBlur={() => setIsOpen(isOpen => !isOpen)}
    >
      <div
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={() => setIsOpen(isOpen => !isOpen)}
        className={clsx(isOpen && 'text-accent')}
      >
        {header}
      </div>

      {isOpen && (
        <div
          className="absolute overflow-hidden mt-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          style={{ right: '-4px' }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

interface DropdownSectionProps {}

export const DropdownSection: React.FC<DropdownSectionProps> = ({
  children,
}) => {
  return <div>{children}</div>;
};

interface DropdownItemProps {
  onClick: (event: SyntheticEvent) => void;
  children: React.ReactNode;
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  onClick,
  children,
}) => {
  return (
    <a
      className="text-gray-700 block px-4 py-2 text-sm cursor-pointer text-center hover:bg-ice"
      onClick={onClick}
      onMouseDown={event => event.preventDefault()} // so OnBlur doesn't get called in parent
    >
      {children}
    </a>
  );
};
