import clsx from 'clsx';
import React from 'react';
import { ClassFragment } from '../../typescript/generated/codegen';
import CookiesUsedNotice from '../app/cookiesUsedNotice';
import FiltersSideModal from '../filters/filtersSideModal/filtersSideModal';
import Footer from './footer/footer';
import Head from './head/head';
import Intercom from './intercom';
import MenuModal from './menuModal';
import Navbar from './navbar/navbar';

interface LayoutProps {
  title: string;
  classes?: string;
  session?: ClassFragment;
  showIntercom?: boolean;
  showFooter?: boolean;
  showNav?: boolean;
  isTransparent?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  title,
  classes,
  session,
  showIntercom = true,
  children,
  showFooter = true,
  showNav = true,
  isTransparent = false,
}) => {
  return (
    <div className={clsx(classes, 'min-h-full ')}>
      <Head title={title} session={session} />

      {showNav && <Navbar isTransparent={isTransparent} />}

      <>{children}</>

      {showFooter && <Footer />}

      {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && (
        <Intercom showIntercom={showIntercom} />
      )}

      <MenuModal />
      <FiltersSideModal />
      <CookiesUsedNotice />
    </div>
  );
};

export default Layout;
