import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { useWindowSize } from '@react-hook/window-size/throttled';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { sessionService } from '../../../application/session';
import { AppContext, Types } from '../../../context';
import { FiltersSection } from '../../../domain/filters';
import generateClassCollectionQuery from '../../../graphql/queries/generateClassCollectionQuery';
import Button from '../../common/button';
import SearchBox from '../searchBox';
import ButtonGroupSection from './buttonGroupSection';
import CheckboxSection from './checkboxSection';
import * as section from './sections';
import TeacherSelect from './teacherSelect/teacherSelect';

interface FiltersSideModalProps {}

interface Values {
  [key: string]: any[];
}

const MODAL_WIDTH = 500;

const FiltersSideModal: React.FC<FiltersSideModalProps> = () => {
  const router = useRouter();
  const { state, dispatch } = useContext(AppContext);
  const [values, setValues] = useState<Values>({});
  const [searchCount, setSearchCount] = useState<number | undefined>();
  const scrollableDiv = useRef<HTMLInputElement>(null);
  const [modalWidth, setModalWidth] = useState<number>(MODAL_WIDTH);

  const [width, _] = useWindowSize();

  useEffect(() => {
    if (width < MODAL_WIDTH) {
      setModalWidth(width);
    }
  }, [width]);

  // disable body scroll when sidemodal is open
  useEffect(() => {
    if (scrollableDiv && scrollableDiv.current) {
      state.modals.isFiltersOpen
        ? disableBodyScroll(scrollableDiv.current, {
            reserveScrollBarGap: true,
          })
        : enableBodyScroll(scrollableDiv.current);
    }
    return () => clearAllBodyScrollLocks();
  }, [state.modals.isFiltersOpen]);

  // keep local state in sync with context level state
  useEffect(() => {
    if (state.filters) setValues(state.filters);
  }, [state.filters]);

  useEffect(() => {
    const fetchSessions = async () => {
      const query = generateClassCollectionQuery({
        filters: values,
        limit: 999,
      });

      const applicationSessions = sessionService();
      const sessions = await applicationSessions.getClasses(query);

      setSearchCount(sessions.length);
    };

    if (
      state.modals.isFiltersOpen &&
      values &&
      Object.values(values).some(arr => arr.length > 0)
    ) {
      fetchSessions();
    } else {
      setSearchCount(undefined);
    }
  }, [values]);

  const handleCloseSidemodal = () =>
    dispatch({
      type: Types.SET_IS_FILTERS_OPEN,
      payload: false,
    });

  const generateQueryString = () => {
    // For each of the values, create a query param mapping and join the values
    // We can decode this on the home page when a user visits from a link with query params set
    Object.keys(values).forEach(key => {
      if (Array.isArray(values[key]) && values[key].length) {
        router.query[encodeURIComponent(key)] = encodeURIComponent(
          values[key].join(',')
        );
      }
    });

    // Push our query into the address bar
    router.push(router.pathname, { query: router.query });
  };

  const handleApply = () => {
    generateQueryString();
    dispatch({
      type: Types.SET_FILTERS,
      payload: values,
    });
    handleCloseSidemodal();
  };

  const handleReset = () => {
    router.push(router.pathname);
    dispatch({ type: Types.RESET_FILTERS });
    handleCloseSidemodal();
  };

  const modalClasses = 'fixed top-0 right-0 h-full';

  const sections: FiltersSection[] = [
    section.teacher(state.settings.teachers),
    section.search,
    section.classification,
    section.type(state.settings.availableFilters.type),
    section.duration,
    section.level,
    section.intensity,
    // section.focus,
    // section.props,
  ];

  return (
    <>
      {/* dark background overlay */}
      <AnimatePresence>
        {state.modals.isFiltersOpen && (
          <motion.div
            onClick={handleCloseSidemodal}
            className={`${modalClasses} w-screen bg-black`}
            style={{ zIndex: 49 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>

      {/* sidemodal component */}
      <AnimatePresence>
        {state.modals.isFiltersOpen && (
          <motion.div
            className={`${modalClasses} max-w-full bg-white`}
            style={{ zIndex: 50, width: modalWidth }}
            initial={{ transform: `translateX(${modalWidth}px)` }}
            animate={{ transform: `translateX(0px)` }}
            exit={{ transform: `translateX(${modalWidth}px)`, opacity: 1 }}
            transition={{ type: 'just' }}
          >
            <div
              data-cy="filter-side-modal"
              className="relative flex flex-col h-full"
            >
              <header className="flex items-center justify-between w-full p-8 text-2xl border-b font-family-semibold">
                <div data-cy="filter-side-modal-heading">Filter</div>
                <div
                  data-cy="filter-side-model-close"
                  className="w-8 h-8 text-gray-400 transition-colors cursor-pointer hover:text-black"
                  onClick={handleCloseSidemodal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </header>

              <div
                className="h-full p-8 space-y-12 overflow-auto"
                ref={scrollableDiv}
              >
                {sections.map(section => {
                  const displayedOptions = section.filterOptionsBy
                    ? section.options.filter((option: any) => {
                        const filterBy = values[section.filterOptionsBy!];
                        return (
                          filterBy &&
                          filterBy.includes(option[section.filterOptionsBy!])
                        );
                      })
                    : section.options;

                  if (displayedOptions.length === 0) return null;

                  return (
                    <div key={section.title}>
                      <div
                        className={`text-xl font-family-semibold mb-4 flex flex-row items-center ${
                          section.isDisabled ? 'text-body-gray' : ''
                        }`}
                      >
                        <span>{section.title}</span>
                        {section.tooltip && (
                          <>
                            <QuestionMarkCircleIcon
                              className="w-4 h-4 ml-1"
                              data-tip={`<div class="text-left">${section.tooltip}</div>`}
                              data-html={true}
                              data-place="right"
                              data-for={`filtersSideModal-${section.tooltip}`}
                            />
                            <ReactTooltip
                              id={`filtersSideModal-${section.tooltip}`}
                            />
                          </>
                        )}
                      </div>

                      {['buttonGroup', 'circleButtonGroup'].includes(
                        section.type
                      ) && (
                        <ButtonGroupSection
                          section={section}
                          allValues={values}
                          setValues={setValues}
                        />
                      )}

                      {section.type === 'checkbox' && (
                        <CheckboxSection
                          section={section}
                          allValues={values}
                          setValues={setValues}
                        />
                      )}

                      {section.type === 'teacherSelect' && (
                        <TeacherSelect
                          filterKey={section.filterKey}
                          options={displayedOptions}
                          allValues={values}
                          setValues={setValues}
                        />
                      )}

                      {section.type === 'search' && (
                        <SearchBox
                          filterKey={section.filterKey}
                          allValues={values}
                          setValues={setValues}
                        />
                      )}
                    </div>
                  );
                })}
              </div>

              <footer className="flex items-center justify-between w-full px-8 py-4 border-t">
                <div
                  data-cy="filter-side-modal-button-reset"
                  onClick={handleReset}
                  className="border-b cursor-pointer border-background"
                >
                  Reset
                </div>
                <div
                  className="flex items-center"
                  data-cy="filter-side-modal-button-apply"
                >
                  {searchCount !== undefined && (
                    <span className="px-4 py-2 mr-4 text-base rounded-full bg-accent text-accent-contrast">
                      {searchCount} Found
                    </span>
                  )}
                  <Button buttonStyle="secondary" onClick={handleApply}>
                    Apply
                  </Button>
                </div>
              </footer>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default FiltersSideModal;
