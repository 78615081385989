import { FiltersSection } from '../../../../domain/filters';

const section: FiltersSection = {
  title: 'Level',
  type: 'circleButtonGroup',
  filterKey: 'level',
  tooltip: [
    '1: Beginner',
    '1-2: Beginner - Intermediate',
    '2: Intermediate',
    '2-3: Intermediate - Advanced',
    '3: Advanced',
  ].join('<br />'),
  options: [
    { label: '1', value: 'Level 1' },
    { label: '1-2', value: 'Level 1-2' },
    { label: '2', value: 'Level 2' },
    { label: '2-3', value: 'Level 2-3' },
    { label: '3', value: 'Level 3' },
  ],
};

export default section;
