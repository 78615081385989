import React, { useEffect, useState } from 'react';
import { cookieAdapter } from '../../adapters/cookieAdapter';
import Button from '../common/button';

const CookiesUsedNotice: React.FC = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const cookiesAdapter = cookieAdapter();

  useEffect(() => {
    const isAccepted = cookiesAdapter.getCookieNoticeAcceptedCookie();
    if (!isAccepted) setIsDisplayed(true);
  }, []);

  const handleAccept = () => {
    cookiesAdapter.setCookieNoticeAcceptedCookie(true);
    setIsDisplayed(false);
  };

  if (!isDisplayed) return null;

  return (
    <div
      className="fixed bottom-0 w-full py-8 mx-auto text-white bg-black"
      style={{ zIndex: 9999999999 }}
    >
      <div className="space-y-6 text-xs content md:flex md:items-center md:justify-between md:space-x-6 md:space-y-0 md:text-base">
        <div>
          We use cookies to personalise content, to provide social media
          features and to analyse our traffic. We also share information about
          your use of our site with our social media, advertising and analytics
          partners who may combine it with other information that you’ve
          provided to them or that they’ve collected from your use of their
          services.
        </div>
        <div data-cy="cookie-notice-accept-button">
          <Button buttonStyle="primary" size="small" onClick={handleAccept}>
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookiesUsedNotice;
