import { AxiosError } from 'axios';
import { CustomError } from 'ts-custom-error';
import { classificationCategoriesCollectionQuery } from '../graphql/queries/classificationCategories';
import generateClassCollectionQuery from '../graphql/queries/generateClassCollectionQuery';
import generateFeatureClassCollectionQuery from '../graphql/queries/generateFeatureClassCollectionQuery';
import generateTeacherCollectionQuery from '../graphql/queries/generateTeacherCollectionQuery';
import {
  ContentfulClassCategoriesDTO,
  ContentfulFeatureSessionsDTO,
  ContentfulQueryGeneratorParams,
  ContentfulSessionsDTO,
  ContentfulTeachersDTO,
} from './backendDTOs';
import contentfulFetcher from './fetchers/contentfulFetcher';
import { loggingAdapter } from './loggingAdapter';
import { ContentfulService, LoggingService } from './ports';

export class ContentfulAdapterError extends CustomError {
  public constructor(
    public statusCode: number,
    message?: string,
    public data?: unknown
  ) {
    super(message);
  }
}

export const _contentfulQuery = async (
  query: string,
  caller: string,
  deps: {
    logger: LoggingService;
    fetcher: (query: string) => Promise<any>;
  }
): Promise<any> => {
  const { logger, fetcher } = deps;

  try {
    const result = await fetcher(query);

    return result;
  } catch (err) {
    const aerr = err as AxiosError;
    logger.error({
      message: `Failed to fetch contentful data`,
      caller,
    });

    throw new ContentfulAdapterError(
      aerr.response?.status || 500,
      aerr.message,
      aerr.response?.data
    );
  }
};

export const _getAllTeachers = async (deps: {
  logger: LoggingService;
  fetcher: (query: string) => Promise<any>;
}): Promise<ContentfulTeachersDTO> => {
  const query = generateTeacherCollectionQuery();
  return _contentfulQuery(query, 'contentfulAdapter._getAllTeachers', deps);
};

export const _getFeatureClasses = async (
  queryParams: ContentfulQueryGeneratorParams,
  deps: {
    logger: LoggingService;
    fetcher: (query: string) => Promise<any>;
  }
): Promise<ContentfulFeatureSessionsDTO> => {
  const query = generateFeatureClassCollectionQuery(queryParams);
  return _contentfulQuery(query, 'contentfulAdapter._getFeatureClasses', deps);
};

export const _getClasses = async (
  contentfulQuery: string,
  deps: {
    logger: LoggingService;
    fetcher: (query: string) => Promise<any>;
  }
): Promise<ContentfulSessionsDTO> => {
  return _contentfulQuery(
    contentfulQuery,
    'contentfulAdapter._getClasses',
    deps
  );
};

export const _getClassById = async (
  id: string,
  deps: {
    logger: LoggingService;
    fetcher: (query: string) => Promise<any>;
  }
): Promise<ContentfulSessionsDTO> => {
  const query = generateClassCollectionQuery({
    where: { id: id },
    returnAll: true,
  });
  return _contentfulQuery(query, 'contentfulAdapter._getClassById', deps);
};

export const _getClassClassifications = async (deps: {
  logger: LoggingService;
  fetcher: (query: string) => Promise<any>;
}): Promise<ContentfulClassCategoriesDTO> => {
  const query = classificationCategoriesCollectionQuery;

  return _contentfulQuery(
    query,
    'contentfulAdapter._getClassClassifications',
    deps
  );
};

export const contentfulAdapter = (): ContentfulService => {
  const logger: LoggingService = loggingAdapter();
  const fetcher: (query: string) => Promise<any> = contentfulFetcher;

  return {
    getAllTeachers: (): Promise<ContentfulTeachersDTO> =>
      _getAllTeachers({ logger, fetcher }),
    getFeatureClasses: (
      queryParams: ContentfulQueryGeneratorParams
    ): Promise<ContentfulFeatureSessionsDTO> =>
      _getFeatureClasses(queryParams, { logger, fetcher }),
    getClasses: (contentfulQuery: string): Promise<ContentfulSessionsDTO> =>
      _getClasses(contentfulQuery, { logger, fetcher }),
    getClassById: (id: string): Promise<ContentfulSessionsDTO> =>
      _getClassById(id, { logger, fetcher }),
    getClassClassifications: (): Promise<ContentfulClassCategoriesDTO> =>
      _getClassClassifications({ logger, fetcher }),
  };
};
