import imageAsset from './imageAsset';
import teacher from './teacher';

const fields = {
  items: {
    displayName: true,
    id: true,
    beid: true,
    name: true,
    description: true,
    classification: true,
    type: true,
    subtype: true,
    level: true,
    videoseconds: true,
    viewCount: true,
    isPublic: true,
    status: true,
    image: imageAsset,
    liveStreamStartsAt: true,
    liveStreamEndsAt: true,
    publishedAt: true,
    teacher: { ...teacher.items },
    spotifyPlaylistUrl: true,
  },
};

export default fields;
