import { SessionVideoCheck } from '../../domain/class';
import { SessionVideoCheckResponseDTO, StreamURL } from '../backendDTOs';

export const sessionCheckDTOToSessionCheck = (
  sessionCheckRespone: SessionVideoCheckResponseDTO
): SessionVideoCheck => {
  const a: Array<StreamURL> = sessionCheckRespone.streamURLs.map(
    (
      x: StreamURL
    ): {
      timeStamp: string;
      streamURL: string;
      status: string;
    } => {
      return {
        timeStamp: x.timeStamp,
        streamURL: x.streamURL,
        status: x.status,
      };
    }
  );

  return {
    streamURL: sessionCheckRespone.streamURL,
    status: sessionCheckRespone.status,
    streamURLs: a,
  };
};
