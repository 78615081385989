import clsx from 'clsx';
import React from 'react';
import { FiltersSection } from '../../../domain/filters';
import { TW_RING } from '../../../domain/websiteDomain';

type Value = any;

interface ButtonGroupSectionProps {
  section: FiltersSection;
  allValues: { [key: string]: any[] };
  setValues: (newValues: any) => void;
}

const ButtonGroupSection: React.FC<ButtonGroupSectionProps> = ({
  section,
  allValues,
  setValues,
}) => {
  const { filterKey, isRadio, options, cols, type } = section;

  const values = allValues[filterKey] || [];

  const handleOnClick = (value: Value) => {
    let newValues: any = {};

    if (values.includes(value)) {
      newValues = isRadio
        ? {
            ...allValues,
            [filterKey]: [],
          }
        : {
            ...allValues,
            [filterKey]: values.filter(v => v !== value),
          };
    } else {
      newValues = isRadio
        ? {
            ...allValues,
            [filterKey]: [value],
          }
        : {
            ...allValues,
            [filterKey]: [...values, value],
          };
    }

    // when selecting 'classification', clear all selected in 'type'
    if (filterKey === 'classification' && newValues?.type) newValues.type = [];

    setValues(newValues);
  };

  return (
    <div
      className={clsx(
        type === 'buttonGroup' && 'grid gap-2',
        type === 'circleButtonGroup' &&
          'flex flex-row items-center space-x-8px sm:space-x-16px',
        !cols && type !== 'circleButtonGroup' && 'grid-cols-2 sm:grid-cols-4',
        cols === 3 && 'grid-cols-3',
        cols === 4 && 'grid-cols-2 sm:grid-cols-4'
      )}
    >
      {options.map(option => {
        const isSelected = values.includes(option.value);
        const dataSelector =
          `${section.type}-${filterKey}-${option.value}`.replace(' ', '_');

        return (
          <button
            data-cy={dataSelector}
            key={`${section.type}-${filterKey}-${option.value}-${option.classification}`}
            className={clsx(
              'font-family-semibold text-black text-center border border-accent-light rounded-full cursor-pointer',
              type === 'buttonGroup' && 'h-48px w-full',
              type === 'circleButtonGroup' &&
                'flex flex-row items-center justify-center h-56px w-56px rounded-full border border-accent-light',
              TW_RING,
              isSelected && 'bg-accent-light',
              !isSelected && 'hover:border-accent'
            )}
            onClick={() => handleOnClick(option.value)}
          >
            {option.label || option.value}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonGroupSection;
