import { CustomError } from 'ts-custom-error';
import { apiAdapter, ApiAdapterError } from '../adapters/apiAdapter';
import {
  CheckCouponResponseDTO,
  StripeCheckoutRequestDTO,
} from '../adapters/backendDTOs';
import { localStorageAdapter } from '../adapters/localStorageAdapter';
import { loggingAdapter } from '../adapters/loggingAdapter';
import {
  ApiService,
  LocalStorageService,
  LoggingService,
  StripeService,
} from '../adapters/ports';
import { stripeService } from '../adapters/stripeAdapter';
import {
  getPricingModelDomain,
  getPricingModels,
  PricingModel,
} from '../domain/billing';
import { User } from '../domain/user';
import { computeFullDomainWithPrefix, isServer } from '../domain/websiteDomain';
import { BMLToConvertTrialPeriodDays } from './constants';

export class BillingApplicationError extends CustomError {
  public constructor(public type: string, message?: string) {
    super(message);
  }
}

export const _billingApplicationErrorFactory = (
  options: { type: string; caller: string; message: string; err: Error },
  defaultDeps: { logging: LoggingService }
): BillingApplicationError => {
  const { logging } = defaultDeps;
  const { caller, message, err, type } = options;

  const error = new BillingApplicationError(type, message);
  logging.error({
    caller: caller,
    message: `${message}: ${err}`,
  });
  logging.error({
    caller: `application.${caller}`,
    message: `Returning application layer error: ${error}`,
  });

  return error;
};

export const _createStripeUrl = async (defaultDeps: {
  api: ApiService;
  logging: LoggingService;
}): Promise<string> => {
  const { api, logging } = defaultDeps;

  try {
    const baseUrl = computeFullDomainWithPrefix();
    const returnUrl = `${baseUrl}/user/profile/#manage-membership`;
    return await api.fetchStripeUrlForUser(returnUrl);
  } catch (err) {
    const apiAdapterError = err as ApiAdapterError;
    throw _billingApplicationErrorFactory(
      {
        type: 'CheckCouponCodeError',
        caller: '_checkCouponCode',
        message: 'Failed when checking coupon code',
        err: apiAdapterError,
      },
      { logging }
    );
  }
};

export const _checkCouponCode = async (
  code: string,
  email: string,
  defaultDeps: { api: ApiService; logging: LoggingService }
): Promise<CheckCouponResponseDTO> => {
  const { api, logging } = defaultDeps;

  try {
    return await api.checkStripePromoCode(code, email);
  } catch (err) {
    const apiAdapterError = err as ApiAdapterError;
    throw _billingApplicationErrorFactory(
      {
        type: 'CheckCouponCodeError',
        caller: '_checkCouponCode',
        message: 'Failed when checking coupon code',
        err: apiAdapterError,
      },
      { logging }
    );
  }
};

export const _createStripeSession = async (
  data: StripeCheckoutRequestDTO,
  defaultDeps: {
    api: ApiService;
    logging: LoggingService;
    storage: LocalStorageService;
  }
) => {
  const { api, logging, storage } = defaultDeps;

  try {
    const d: StripeCheckoutRequestDTO = {
      ...data,
      coupon: storage.getCouponData() || undefined,
    };
    return await api.createStripeSession(d);
  } catch (err) {
    const apiAdapterError = err as ApiAdapterError;

    throw _billingApplicationErrorFactory(
      {
        type: 'CheckCouponCodeError',
        caller: '_createCheckout',
        message: 'Failed to create checkout session',
        err: apiAdapterError,
      },
      { logging }
    );
  }
};

export const _buildStripeCheckUrls = (
  user: User,
  priceId: string,
  baseUrl: string,

  defaultDeps: { logging: LoggingService }
): StripeCheckoutRequestDTO => {
  const { logging } = defaultDeps;

  logging.info({
    caller: '_buildStripeCheckUrls',
    message: `Found baseUrl to be ${baseUrl}`,
  });

  const d: StripeCheckoutRequestDTO = {
    priceId,
    trialPeriodDays: user.BMLToConvert
      ? BMLToConvertTrialPeriodDays
      : undefined,
    cancelUrl: `${baseUrl}/user/subscribe`,
    successUrl: `${baseUrl}/user/subscribe/success`,
  };

  return d;
};

export const _handleSubscribe = async (
  purchasedContractId: string,
  user: User,
  defaultDeps: {
    storage: LocalStorageService;
    logging: LoggingService;
    api: ApiService;
    stripe: StripeService;
  }
): Promise<void> => {
  const { storage, logging, api, stripe } = defaultDeps;

  const baseUrl = isServer
    ? computeFullDomainWithPrefix()
    : window.location.origin;
  const d: StripeCheckoutRequestDTO = _buildStripeCheckUrls(
    user,
    purchasedContractId,
    baseUrl,
    { logging }
  );

  try {
    const checkout = await _createStripeSession(d, { api, logging, storage });
    await stripe.redirectToCheckout(checkout.sessionID);
  } catch (err) {
    const billingError = err as BillingApplicationError;
    throw _billingApplicationErrorFactory(
      {
        type: 'HandleSubscribeError',
        caller: '_handleSubscribe',
        message: 'Failed to redirect to stripe',
        err: billingError,
      },
      { logging }
    );
  }
};

export const _getPricingModel = async (
  user: User,
  deps: { api: ApiService; logging: LoggingService }
): Promise<PricingModel | undefined> => {
  const { api, logging } = deps;

  if (!user || !user.id) return undefined;

  try {
    const stripeAndUserData = await Promise.all([
      api.fetchUserPrivate(user.id),
      api.fetchStripePrices(),
    ]);

    const [userPrivateData, stripePrices] = stripeAndUserData;

    if (!userPrivateData.planName) {
      throw Error('Could not determine billing plan');
    }

    const pricingModels = getPricingModels(stripePrices);

    const pricingModel = getPricingModelDomain(
      userPrivateData.planName,
      pricingModels
    );

    return pricingModel;
  } catch (error) {
    const errorCast = error as Error;
    throw _billingApplicationErrorFactory(
      {
        type: 'HandlePricing',
        caller: '_getPricingModel',
        message: 'Failed to get pricing model',
        err: errorCast,
      },
      { logging }
    );
  }
};

export function billingService() {
  const api: ApiService = apiAdapter();
  const logging: LoggingService = loggingAdapter();
  const storage: LocalStorageService = localStorageAdapter();
  const stripe: StripeService = stripeService();

  return {
    goToStripeBillingPortal: (): Promise<any> =>
      _createStripeUrl({ api, logging }),
    checkPromoCode: (code: string, email: string) =>
      _checkCouponCode(code, email, { api, logging }),
    createStripeSession: (data: StripeCheckoutRequestDTO) =>
      _createStripeSession(data, { api, logging, storage }),
    buildStripeCallbackUrls: (user: User, price: string, baseUrl: string) =>
      _buildStripeCheckUrls(user, price, baseUrl, { logging }),
    handleSubscribe: (purchasedContractId: string, user: User): Promise<void> =>
      _handleSubscribe(purchasedContractId, user, {
        storage,
        logging,
        api,
        stripe,
      }),
    getPricingModel: (user: User): Promise<PricingModel | undefined> =>
      _getPricingModel(user, { api, logging }),
  };
}
