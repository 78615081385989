import { FiltersSection } from '../../../../domain/filters';

const section: FiltersSection = {
  title: 'Practice',
  type: 'buttonGroup',
  filterKey: 'classification',
  options: [
    { value: 'Yoga' },
    { value: 'Pilates' },
    { value: 'Fitness' },
    { label: 'Mind', value: 'Mindfulness' },
    { value: 'Music' },
  ],
};

export default section;
