import { NextSeo } from 'next-seo';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { config } from '../../../domain/siteConfig';
import { ClassFragment } from '../../../typescript/generated/codegen';

interface HeadProps {
  title: string;
  session?: ClassFragment;
}

const Head: React.FC<HeadProps> = ({ title, session }) => {
  const router = useRouter();

  const pageTitle = `${title} | ${config.title}`;
  const url = `${process.env.NEXT_PUBLIC_APP_ROOT_DOMAIN}${router.asPath}`;
  const ogTitle = session
    ? `${session.name?.trim()} with ${session.teacher?.firstName} ${
        session.teacher?.lastName
      }`
    : config.title;

  return (
    <>
      <NextHead>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest"></link>
      </NextHead>

      <NextSeo
        title={pageTitle}
        openGraph={{
          url,
          title: ogTitle,
          description: session?.description || config.description,
        }}
      />
    </>
  );
};

export default Head;
