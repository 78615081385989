import dayjs from 'dayjs';
import { Price } from '../adapters/backendDTOs';
import { centsToDollars, currencies, Currency } from '../libs/currencies';
import { User } from './user';

export enum PricingModelId {
  Casual = 'Casual',
  Standard = 'Standard',
  Premium = 'Premium',
}

export interface PricingModel {
  planId: PricingModelId;
  period: 'month' | 'hour';
  planShortName: string;
  planSubtitle: string;
  priceInDollars: number;
  stripeId: string;
  planBenefits: {
    text: string;
    shouldHighlight?: boolean;
  }[];
}

export type PricingCardGroupData = {
  cards: Array<PricingModelId>;
};

export const pricingPlanNameToStripeDataPlanNameMap: {
  [key in PricingModelId]: string;
} = {
  [PricingModelId.Casual]: 'Casual',
  [PricingModelId.Standard]: 'Standard',
  [PricingModelId.Premium]: 'Premium',
};

// Takes an array of prices and finds the pricing object with a given plan name
// Returns only the single pricing objet that is found
export const pricingObjectFromMetaData = (
  planId: PricingModelId,
  prices: Price[]
): Price => {
  const res = prices.find(
    (p: Price) =>
      p.metadata.planName === pricingPlanNameToStripeDataPlanNameMap[planId]
  );

  if (!res) {
    throw new Error(`Could not find pricing object for plan ${planId}`);
  }

  return res;
};

export const getFilteredPrices = (
  stripePrices: Price[],
  currency: Currency
): Price[] => {
  const prices: Price[] = stripePrices
    .filter(
      price => price.currency.toUpperCase() === currency.code.toUpperCase()
    )
    .filter(price => !!price.unit_amount)
    .sort((a, b) => {
      if (!a.unit_amount || !b.unit_amount) return 0;
      if (a.unit_amount < b.unit_amount) return -1;
      if (a.unit_amount > b.unit_amount) return 1;
      return 0;
    });

  return prices;
};

export const getBillingCurrency = (stripePrices: Price[]): Currency => {
  const stripeCurrency = stripePrices[0].currency.toUpperCase();

  // object keys are a litle funky in typescript. This is a hack to make it not through a type checking error
  return (currencies as any)[stripeCurrency];
};

export const getMaxPriceFromList = (priceList: Price[]): number => {
  const p = Math.max(
    ...priceList.map(price =>
      price.unit_amount && price.recurring?.interval === 'year'
        ? price.unit_amount / 12
        : price.unit_amount || 0
    )
  );

  return p;
};

// Hardcoded mapping between stripe data and plans
export const getPricingModels = (priceList: Price[]): PricingModel[] => {
  const casualPriceData = pricingObjectFromMetaData(
    PricingModelId.Casual,
    priceList
  );
  const standardPriceData = pricingObjectFromMetaData(
    PricingModelId.Standard,
    priceList
  );
  const unlimitedPriceData = pricingObjectFromMetaData(
    PricingModelId.Premium,
    priceList
  );

  const models: PricingModel[] = [
    {
      planId: PricingModelId.Casual,
      period: 'hour',
      planShortName: 'Casual',
      planSubtitle: 'Pay as you go',
      planBenefits: [
        {
          text: 'Access to our growing library of more than 1,200 foundational and advanced classes',
        },
        { text: 'Pay by the hour', shouldHighlight: true },
        { text: 'Billed monthly' },
        { text: 'Access to our BML community group' },
      ],
      priceInDollars: centsToDollars(casualPriceData.unit_amount),
      stripeId: casualPriceData.id,
    },
    {
      planId: PricingModelId.Standard,
      period: 'month',
      planShortName: 'Standard',
      planSubtitle: 'Foundational access',
      planBenefits: [
        {
          text: 'Access to our growing library of more than 500 foundational classes and meditation sessions',
        },
        { text: 'Unlimited viewing', shouldHighlight: true },
        {
          text: 'Renewed monthly',
        },
        { text: 'Access to our BML community group' },
      ],
      priceInDollars: centsToDollars(standardPriceData.unit_amount),
      stripeId: standardPriceData.id,
    },
    {
      planId: PricingModelId.Premium,
      period: 'month',
      planShortName: 'Premium',
      planSubtitle: 'Full access',
      planBenefits: [
        {
          text: 'Access to our growing library of more than 1,200 foundational and advanced classes',
        },
        {
          text: 'Unlimited viewing',
          shouldHighlight: true,
        },
        {
          text: 'Renewed monthly',
        },
        {
          text: 'Access to our BML community group',
        },
        {
          text: 'Access to our premium community group',
        },
        {
          text: 'Exclusive access to monthly live community events with our teachers',
        },
      ],
      priceInDollars: centsToDollars(unlimitedPriceData.unit_amount),
      stripeId: unlimitedPriceData.id,
    },
  ];

  return models;
};

export const getPricingModelDomain = (
  pricingModelId: PricingModelId,
  pricingModels: PricingModel[]
): PricingModel => {
  const pricingModel = pricingModels.find(pm => pm.planId === pricingModelId);

  if (!pricingModel) {
    throw new Error('could not find pricing model');
  }

  return pricingModel;
};

export const getHeading = ({
  planShortName,
  priceInDollars,
  period,
}: PricingModel): string => {
  return `${planShortName} - $${priceInDollars}/${period}`;
};

// Billing Status

export enum BillingStatusID {
  Active = 'active',
  Paid = 'paid',
  PastDue = 'past_due',
  Unpaid = 'unpaid',
  Cancelled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  Cancelling = 'cancelling',
}

export type BillingStatus = {
  id: BillingStatusID;
  niceName: string;
};

const billingStatuses: BillingStatus[] = [
  {
    id: BillingStatusID.Active,
    niceName: 'Active',
  },
  {
    id: BillingStatusID.Paid,
    niceName: 'Active',
  },
  {
    id: BillingStatusID.PastDue,
    niceName: 'Past Due',
  },
  {
    id: BillingStatusID.Unpaid,
    niceName: 'Unpaid',
  },
  {
    id: BillingStatusID.Cancelled,
    niceName: 'Cancelled',
  },
  {
    id: BillingStatusID.Incomplete,
    niceName: 'Payment details are incomplete',
  },
  {
    id: BillingStatusID.IncompleteExpired,
    niceName: 'Payment details are incomplete and expired',
  },
  {
    id: BillingStatusID.Trialing,
    niceName: 'Trial',
  },
  {
    id: BillingStatusID.Cancelling,
    niceName: 'Cancelling',
  },
];

export const getBillingStatus = (user: User) => {
  return billingStatuses.find(b => b.id === user.billingStatus);
};

export const getModelBreakdown = (
  { period, priceInDollars, planId }: PricingModel,
  nextPaymentDate: Date,
  user: User
): string => {
  switch (user.billingStatus) {
    case BillingStatusID.Paid:
    case BillingStatusID.Active: {
      if (planId === PricingModelId.Casual) {
        return `You will be charged on a per usage basis.`;
      }
      const paymentDateNice = dayjs(nextPaymentDate).format('MMMM D, YYYY');
      return `Your next payment is for $${priceInDollars} on ${paymentDateNice}. You will continue to be charged $${priceInDollars} each ${period} thereafter.`;
    }
    case BillingStatusID.PastDue: {
      return 'Your account is past due. Please manage payment to keep access to account';
    }
    case BillingStatusID.Cancelled: {
      return 'You have cancelled your subscription. You will not be charged until you reactivate your subscription.';
    }
    case BillingStatusID.Incomplete: {
      return 'You have not completed your subscription. Please manage payment to access service.';
    }
    case BillingStatusID.Trialing: {
      return 'You are currently on a trial. You will not be charged until your trial has completed.';
    }
    default: {
      return '';
    }
  }
};
