import { gql } from 'graphql-request';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { State as Filters } from '../../context/filters';
import { getDurationRange } from '../../domain/filters';
import fields from '../fragments/featureClass';

const generateFeatureClassCollectionQuery = ({
  filters = {},
  where = {},
  order = null,
  limit = 20,
}: {
  filters?: Filters;
  where?: any;
  order?: any;
  limit?: number;
}) => {
  const filterBlacklist = ['focus', 'goal'];

  const queryFromFilters = filters
    ? Object.entries(filters).reduce((acc, cur) => {
        const [key, value] = cur;

        // if filter key is in blacklist then don't add to query
        if (filterBlacklist.includes(key)) return acc;

        // if value is set and not an empty array
        if (value && value.length) {
          switch (key) {
            case 'teacher': {
              acc = {
                ...acc,
                teacher: { BEID_in: value },
              };
              break;
            }

            case 'duration': {
              const durationRange = getDurationRange(value[0]);

              if (!durationRange) break;

              acc = {
                ...acc,
                videoseconds_gte: durationRange?.min * 60,
                videoseconds_lt: durationRange?.max * 60,
              };
              break;
            }

            default: {
              acc = {
                ...acc,
                [`${key}_in`]: value,
              };
              break;
            }
          }
        }

        return acc;
      }, {})
    : {};

  const q = {
    featureClassCollection: {
      __args: { where: { ...queryFromFilters, ...where }, order, limit },
      ...fields,
    },
  };

  const graphql_query = jsonToGraphQLQuery(q, { pretty: true });

  return gql`{
    ${graphql_query}
  }`;
};

export default generateFeatureClassCollectionQuery;
