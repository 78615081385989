import { FiltersSection } from '../../../../domain/filters';

const section: FiltersSection = {
  title: 'Search Terms',
  type: 'search',
  filterKey: 'searchTerm',
  tooltip:
    'Search for terms across class names, descriptions and teacher names',
  // @TODO - options are irrelevant for this but we need it to satisfy TS
  options: [{ value: 'dummy' }],
};

export default section;
