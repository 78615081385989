import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { ContentfulQueryGeneratorParams } from '../../adapters/backendDTOs';
import { getDurationRange } from '../../domain/filters';
import fields from '../fragments/class';

const generateClassCollectionQuery = ({
  filters = {},
  where = {},
  order = null,
  limit = 20,
  returnAll = false,
}: ContentfulQueryGeneratorParams) => {
  const filterBlacklist = ['focus', 'goal'];

  const queryFromFilters = filters
    ? Object.entries(filters).reduce((acc, cur) => {
        const [key, value] = cur;

        // if filter key is in blacklist then don't add to query
        if (filterBlacklist.includes(key)) return acc;

        // if value is set and not an empty array
        if (value && value.length) {
          switch (key) {
            case 'teacher': {
              acc = {
                ...acc,
                teacher: { BEID_in: value },
              };
              break;
            }

            case 'duration': {
              // Get duration ranges based on filter values
              const durationRanges = value.map(v =>
                getDurationRange(parseInt(v))
              );

              // Convert our ranges into Contentful queryable objects to use in our OR clause
              const durationValues = durationRanges.map(duration => {
                if (duration) {
                  return {
                    videoseconds_gte: duration?.min * 60,
                    videoseconds_lt: duration?.max * 60,
                  };
                }
              });

              if (!durationValues?.length || !durationValues[0]) break;

              acc = {
                ...acc,
                OR: [...durationValues],
              };
              break;
            }

            case 'searchTerm': {
              acc = {
                ...acc,
                OR: [
                  { name_contains: value[0] },
                  { description_contains: value[0] },
                  {
                    teacher: {
                      displayName_contains: value[0],
                    },
                  },
                ],
              };
              break;
            }

            default: {
              acc = {
                ...acc,
                [`${key}_in`]: value,
              };
              break;
            }
          }
        }

        return acc;
      }, {})
    : {};

  const q = {
    classCollection: {
      __args: {
        where: {
          ...queryFromFilters,
          ...where,
          ...(returnAll ? {} : { publishedAt_lte: dayjs().toISOString() }),
        },
        order,
        limit,
      },
      ...fields,
    },
  };

  const graphql_query = jsonToGraphQLQuery(q, { pretty: true });

  return gql`{
    ${graphql_query}
  }`;
};

export default generateClassCollectionQuery;
