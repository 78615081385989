const imageAsset = {
  sys: { id: true },
  title: true,
  description: true,
  url: true,
  width: true,
  height: true,
};

export default imageAsset;
