import React from 'react';
import { components } from 'react-select';
import Avatar from '../../../teacher/avatar';

interface OptionProps {}

const Option: React.FC<OptionProps> = (props: any) => {
  const { isFocused, data } = props;
  const { teacher } = data;

  return (
    <components.Option {...props}>
      <div
        className={`flex items-center p-3 cursor-pointer ${
          isFocused ? 'bg-black text-white' : ''
        }`}
      >
        <Avatar teacher={teacher} size={36} />
        <div className="ml-2">{props.children}</div>
      </div>
    </components.Option>
  );
};

export default Option;
