const section: any = (
  options: { value: string; classification: string }[]
) => ({
  title: 'Style',
  type: 'checkbox',
  filterKey: 'type',
  filterOptionsBy: 'classification',
  options,
});

export default section;
