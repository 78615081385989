import React from 'react';
import { components } from 'react-select';
import Avatar from '../../../teacher/avatar';

interface MultiValueRemoveProps {}

const MultiValueRemove: React.FC<MultiValueRemoveProps> = (props: any) => {
  const { data } = props;

  return (
    <components.MultiValueRemove {...props}>
      <div className="px-3 py-2 flex items-center bg-black text-white cursor-pointer hover:text-accent-hover transition-colors">
        <Avatar teacher={data.teacher} size={36} />
        <div data-cy="teacher-select-selected-teacher-name" className="ml-2">
          {data.label}
        </div>
      </div>
    </components.MultiValueRemove>
  );
};

export default MultiValueRemove;
