import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context';
import { homepageRoute, marketingRoute } from '../../../domain/websiteDomain';
import useUserWithRedirects from '../../../hooks/useUser';
import Logo from './../../../components/icons/logo';
import FetchingMessage from './fetchingMessage';
import LoginSection from './loginSection';
import MenuIcon from './menuIcon';
import UserSection from './userSection';

interface NavbarProps {
  isTransparent?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isTransparent = false }) => {
  const { state } = useContext(AppContext);
  const user = useUserWithRedirects();
  const router = useRouter();
  const [scrollPercentage, setScrollPercentage] = useState(1);

  // effects and hooks for determining percentage of scroll position from top of page
  // handles fade in/out of navbar elements on pages with hero image
  useEffect(() => {
    if (typeof window !== 'undefined') setScrollPosition(window.pageYOffset);
  }, [router.asPath]);

  const setScrollPosition = (currentPosition: number) => {
    const percentage = -currentPosition / (window.innerHeight - 112);

    if (typeof window !== 'undefined' && isTransparent && percentage < 1) {
      setScrollPercentage(percentage);
    } else if (scrollPercentage !== 1) {
      setScrollPercentage(1);
    }
  };

  // use scroll position hook
  useScrollPosition(({ currPos }) => {
    setScrollPosition(currPos.y);
  });

  return (
    <div
      className={`h-navbar flex items-center justify-between select-none text-white top-0 z-50 w-full px-4 ${
        isTransparent ? 'fixed' : 'sticky'
      }`}
      style={{
        backgroundColor: `rgb(38, 38, 38, ${scrollPercentage})`,
      }}
    >
      <Link
        href={
          user.isLoggedIn || process.env.NODE_ENV === 'development'
            ? homepageRoute
            : marketingRoute
        }
      >
        <a aria-label="Home">
          <Logo height="40" width="180" />
        </a>
      </Link>

      <div className="flex items-center h-full">
        {state.settings.isFetching ? (
          <FetchingMessage className="hidden sm:block" />
        ) : (
          <>{user?.isLoggedIn ? <UserSection /> : <LoginSection />}</>
        )}
        <MenuIcon />
      </div>
    </div>
  );
};

export default Navbar;
