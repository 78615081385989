export type ExternalLinkProps = {
  children: React.ReactNode;
};

export const ExternalLink: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement> | ExternalLinkProps
> = ({ children, ...props }) => {
  return (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};
