export const classificationCategoriesCollectionQuery = `query {
  classificationCategoriesCollection(limit: 8) {
    total
    items {
      classification
      categories
      defaultIndex
    }
  }
}
`;
