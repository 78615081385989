import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef } from 'react';
import { AppContext, Types } from '../../context';
import { showBMLToConvertAction, showSubscribeAction } from '../../domain/user';
import { homepageRoute, routes } from '../../domain/websiteDomain';
import useUserWithRedirects from '../../hooks/useUser';

interface MenuModalProps {}

const MenuModal: React.FC<MenuModalProps> = () => {
  const router = useRouter();
  const { state, dispatch } = useContext(AppContext);
  const user = useUserWithRedirects();
  const scrollableDiv = useRef<HTMLInputElement>(null);

  const isOpen = state.modals.isMenuOpen;

  // disable body scroll when sidemodal is open
  useEffect(() => {
    if (scrollableDiv && scrollableDiv.current) {
      isOpen
        ? disableBodyScroll(scrollableDiv.current, {
            reserveScrollBarGap: true,
          })
        : enableBodyScroll(scrollableDiv.current);
    }
    return () => clearAllBodyScrollLocks();
  }, [isOpen]);

  const handleClose = () =>
    dispatch({ type: Types.SET_IS_MENU_OPEN, payload: false });

  const handleNavigateTo = (route: string) => {
    dispatch({
      type: Types.SET_IS_MENU_OPEN,
      payload: false,
    });
    router.push(route).then(() => window.scrollTo(0, 0));
  };

  const handleLogout = () => {
    dispatch({ type: Types.LOGOUT });
    dispatch({ type: Types.RESET_FILTERS });
    dispatch({ type: Types.SET_RECOMMENDATIONS, payload: [] });
    dispatch({
      type: Types.SET_IS_MENU_OPEN,
      payload: false,
    });
    router.push(homepageRoute);
  };

  return (
    <>
      {/* dark background overlay */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            onClick={handleClose}
            className={`fixed inset-0 w-screen bg-black`}
            style={{ zIndex: 39 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={scrollableDiv}
            className="fixed top-0 right-0 h-full pt-20 bg-background"
            style={{ zIndex: 40, width: '80vw' }}
            initial={{ transform: `translateX(700px)` }}
            animate={{ transform: `translateX(0px)` }}
            exit={{ transform: `translateX(700px)`, opacity: 1 }}
            transition={{ type: 'just' }}
          >
            <div className="h-full p-10 text-2xl text-accent">
              <div className="flex flex-col justify-between h-full">
                <div className="space-y-8">
                  {user.isLoggedIn && (
                    <>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleNavigateTo(routes.USER_PROFILE)}
                      >
                        Your Profile
                      </div>

                      <div className="cursor-pointer" onClick={handleLogout}>
                        Logout
                      </div>
                    </>
                  )}

                  {showBMLToConvertAction(user, router) && (
                    <div
                      className="cursor-pointer"
                      onClick={() => handleNavigateTo(routes.SUBSCRIBE)}
                    >
                      Convert Your Membership
                    </div>
                  )}

                  {showSubscribeAction(user, router) && (
                    <div
                      className="cursor-pointer"
                      onClick={() => handleNavigateTo(routes.SUBSCRIBE)}
                    >
                      Activate Subscription
                    </div>
                  )}

                  {!user.isLoggedIn && (
                    <>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleNavigateTo(routes.LOGIN)}
                      >
                        Login
                      </div>

                      <div
                        className="cursor-pointer"
                        onClick={() => handleNavigateTo(routes.HOME)}
                      >
                        Find a class
                      </div>

                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          handleNavigateTo(routes.MARKETING_TEACHERS)
                        }
                      >
                        Our teachers
                      </div>

                      <div
                        className="cursor-pointer"
                        onClick={() => handleNavigateTo(routes.SIGNUP)}
                      >
                        Start your free trial
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MenuModal;
