import clsx from 'clsx';
import React, { useState } from 'react';

interface NewsletterSignupProps {}

const NewsletterSignup: React.FC<NewsletterSignupProps> = () => {
  const [email, setEmail] = useState('');

  return (
    <div className="space-y-4">
      <div className="text-center font-family-semibold text-20px sm:text-left">
        Join our newsletter
      </div>
      <div className="text-center sm:text-left">
        Be part of our journey and know when new features and classes are added.
      </div>
      <form
        action="https://online.us1.list-manage.com/subscribe/post"
        method="POST"
      >
        <input type="hidden" name="u" value="8e2ea63436449f5b24ddbfdcb" />
        <input type="hidden" name="id" value="b8bb949c30" />
        <div
          className={clsx(
            'grid grid-cols-1 gap-0 space-y-4',
            'sm:grid-cols-3 sm:gap-4 sm:space-y-0',
            'lg:grid-cols-1 lg:gap-0 lg:space-y-4'
          )}
        >
          <input
            type="email"
            name="MERGE0"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
            className="text-black col-span-2 bg-[#e4eae1] h-12 focus:ring-2 focus:ring-offset-2 focus:ring-offset-background-dark focus:ring-accent focus:outline-none focus:border-none"
          />
          <button
            type="submit"
            className="w-full h-12 text-white bg-accent font-family-semibold focus:ring-2 focus:ring-offset-2 focus:ring-offset-background-dark focus:ring-accent focus:outline-none focus:border-none"
          >
            Subscribe
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewsletterSignup;
