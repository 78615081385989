import React, { useEffect, useState } from 'react';
import { setIsIntercomVisible } from '../../adapters/intercomAdapter';
import { userService } from '../../application/user';
import useUserWithRedirects from '../../hooks/useUser';

interface IntercomProps {
  showIntercom: boolean;
}

const Intercom: React.FC<IntercomProps> = ({ showIntercom }) => {
  const user = useUserWithRedirects();
  const [isScriptSet, setIsScriptSet] = useState(false);

  useEffect(() => {
    const applicationUser = userService();
    if (typeof window !== 'undefined') {
      const intercomSettings =
        user.isLoggedIn && applicationUser.getUserAuthCookie()
          ? {
              app_id: 'h7qe4wuo',
              user_id: user.id,
              name: `${user.firstName} ${user.lastName}`,
              user_hash: applicationUser.getUserAuthCookie()?.intercom_hash,
            }
          : { app_id: 'h7qe4wuo' };

      window.intercomSettings = intercomSettings;

      if (window.Intercom) window.Intercom('update', intercomSettings);

      if (!isScriptSet) {
        const script = document.createElement('script');
        script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/h7qe4wuo';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
        document.body.appendChild(script);
        setIsScriptSet(true);
      }
    }
  }, [user.isLoggedIn]);

  // show/hide chat widget
  useEffect(() => setIsIntercomVisible(showIntercom), [showIntercom]);

  return null;
};

export default Intercom;
