import imageAsset from './imageAsset';

const fields = {
  items: {
    beid: true,
    slug: true,
    displayName: true,
    firstName: true,
    lastName: true,
    description: true,
    gender: true,
    country: true,
    image: imageAsset,
    heroImage: imageAsset,
    image43: imageAsset,
  },
};

export default fields;
