import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';
import LoadingSpinner from './loadingSpinner';

interface ButtonProps {
  buttonStyle: 'primary' | 'secondary' | 'ternary';
  type?: 'button' | 'submit' | 'reset';
  href?: string;
  onClick?: any;
  classes?: string;
  isDisabled?: boolean;
  isSubmitting?: boolean;
  size?: 'small' | 'regular';
  id?: string;
}

const Button: React.FC<ButtonProps> = ({
  buttonStyle,
  type,
  href,
  onClick,
  children,
  classes,
  isDisabled,
  isSubmitting,
  size = 'regular',
  id,
}) => {
  const buttonClasses = clsx(
    'transition-colors relative font-family-semibold',
    'focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2',
    'flex items-center justify-center',
    'rounded',
    classes,

    {
      'bg-accent hover:bg-accent-hover border-accent hover:border-accent-hover text-accent-contrast border-2':
        buttonStyle === 'primary',
      'bg-white hover:bg-accent-hover hover:text-white hover:border-accent-hover border-black text-black border-2':
        buttonStyle === 'secondary',
      'bg-white hover:bg-ice border-ice text-black border':
        buttonStyle === 'ternary',
    },
    {
      'px-4 h-56px md:px-6 text-base': size === 'regular',
      'h-[36px] px-16px': size === 'small',
    }
  );

  if (isDisabled || isSubmitting) {
    const disabledButtonStyles = clsx({
      'bg-accent-light border-accent-light hover:border-accent-light hover:bg-accent-light':
        buttonStyle === 'primary',
      'border-opacity-40 text-opacity-40 hover:border-opacity-40 hover:text-opacity-40 hover:text-black hover:border-black':
        buttonStyle === 'secondary',
    });

    return (
      <button
        type={type}
        className={`${buttonClasses} ${disabledButtonStyles} cursor-not-allowed	`}
        disabled
        id={id}
      >
        {isSubmitting && <LoadingSpinner />}
        {children}
      </button>
    );
  }

  if (onClick) {
    return (
      <button type={type} onClick={onClick} className={buttonClasses} id={id}>
        {children}
      </button>
    );
  }

  if (href) {
    return (
      <Link href={href}>
        <a type={type} className={buttonClasses + ' w-fit'} id={id}>
          {children}
        </a>
      </Link>
    );
  }

  return (
    <button type={type} className={buttonClasses} id={id}>
      {children}
    </button>
  );
};

export default Button;
