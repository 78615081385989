import React, { useContext } from 'react';
import { AppContext, Types } from '../../../context';

interface MenuIconProps {}

const MenuIcon: React.FC<MenuIconProps> = () => {
  const { state, dispatch } = useContext(AppContext);

  const handleOnClick = () =>
    dispatch({
      type: Types.SET_IS_MENU_OPEN,
      payload: !state.modals.isMenuOpen,
    });

  if (state.modals.isFiltersOpen) return null;

  return (
    <div
      className="w-10 h-10 transition-colors cursor-pointer sm:hidden hover:text-accent-hover"
      onClick={handleOnClick}
    >
      {state.modals.isMenuOpen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      )}
    </div>
  );
};

export default MenuIcon;
