import { FiltersSection } from '../../../../domain/filters';

const section: FiltersSection = {
  title: 'Intensity',
  type: 'circleButtonGroup',
  filterKey: 'intensity',
  tooltip: [
    '1: Very Light',
    '2: Light',
    '3: Moderate',
    '4: Vigorous',
    '5: High',
  ].join('<br />'),
  options: [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
  ],
};

export default section;
