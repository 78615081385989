import {
  ClassFragment,
  TeacherFragment,
} from '../../typescript/generated/codegen';
import {
  ContentfulClassCategories,
  ContentfulClassCategoriesDTO,
  ContentfulFeatureSessionsDTO,
  ContentfulSessionsDTO,
  ContentfulTeachersDTO,
} from '../backendDTOs';

export const teachersDTOtoTeachers = (
  incoming: ContentfulTeachersDTO
): TeacherFragment[] => {
  return incoming?.data?.teacherCollection?.items || [];
};

export const featureSessionsDTOToSessions = (
  incoming: ContentfulFeatureSessionsDTO
): ClassFragment[] => {
  return (
    incoming?.data?.featureClassCollection?.items?.map(fc => fc.class) || []
  );
};

export const sessionsDTOToSessions = (
  incoming: ContentfulSessionsDTO
): ClassFragment[] => {
  return incoming?.data?.classCollection?.items || [];
};

export const sessionsDTOToSession = (
  incoming: ContentfulSessionsDTO
): ClassFragment => {
  return incoming?.data?.classCollection?.items[0];
};

export const classCategoriesDTOToClassCategories = (
  incoming: ContentfulClassCategoriesDTO
): ContentfulClassCategories[] => {
  return incoming?.data?.classificationCategoriesCollection?.items;
};
