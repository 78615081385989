import React from 'react';
import Select, { ActionMeta } from 'react-select';
import { TeacherFragment } from '../../../../typescript/generated/codegen';
import MultiValueLabel from './multiValueLabel';
import MultiValueRemove from './multiValueRemove';
import Option from './option';

type Value = any;
type OptionType = { label?: string; value: Value; teacher?: TeacherFragment };

interface TeacherSelectProps {
  filterKey: string;
  options: OptionType[];
  allValues: { [key: string]: any[] };
  setValues: (newValues: any) => void;
}

const TeacherSelect: React.FC<TeacherSelectProps> = ({
  filterKey,
  options,
  allValues,
  setValues,
}) => {
  const values = allValues[filterKey] || [];

  const selectValue = options.filter(option => values.includes(option.value));

  const setValue = (_selected: any, action: ActionMeta<OptionType>) => {
    if (action.action === 'select-option') {
      setValues({
        ...allValues,
        [filterKey]: [...values, action.option?.value],
      });
    } else if (action.action === 'remove-value') {
      setValues({
        ...allValues,
        [filterKey]: values.filter(v => v !== action?.removedValue?.value),
      });
    } else if (action.action === 'clear') {
      setValues({
        ...allValues,
        [filterKey]: [],
      });
    }
  };

  return (
    <div>
      <Select
        id="filter-teacher-select"
        options={options}
        value={selectValue}
        onChange={setValue}
        isMulti
        isSearchable
        closeMenuOnSelect={false}
        aria-label="teacher select"
        components={{ MultiValueLabel, MultiValueRemove, Option }}
        styles={{
          multiValueRemove: base => ({
            ...base,
            padding: 0,
          }),
          option: base => ({
            ...base,
            padding: 0,
          }),
        }}
      />
    </div>
  );
};

export default TeacherSelect;
