import React from 'react';

interface LoadingSpinnerProps {
  color?: string;
  classes?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  color = '#fff',
  classes,
}) => {
  const borderColor = `${color} transparent transparent transparent`;

  return (
    <div className={`${classes} lds-ring`} data-cy="loading-spinner">
      <div style={{ borderColor: borderColor }}></div>
      <div style={{ borderColor: borderColor }}></div>
      <div style={{ borderColor: borderColor }}></div>
      <div style={{ borderColor: borderColor }}></div>
    </div>
  );
};

export default LoadingSpinner;
