import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context';
import { SearchIcon } from '@heroicons/react/outline';

interface SearchBoxProps {
  allValues: { [key: string]: any[] };
  setValues: (newValues: any) => void;
  filterKey: string;
}

const SearchBox: React.FC<SearchBoxProps> = ({
  setValues,
  allValues,
  filterKey,
}) => {
  const { state } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState(
    (state?.filters?.searchTerm && state.filters.searchTerm[0]) || ''
  );

  useEffect(() => {
    setValues({
      ...allValues,
      [filterKey]: searchTerm ? [searchTerm] : [],
    });
  }, [searchTerm]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="relative flex items-center">
      <SearchIcon className="absolute w-6 h-6 ml-3" />
      <input
        data-cy="filter-shade-model-search-input"
        className="pl-12 w-full rounded-md border border-accent-light focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-white focus:ring-accent-light hover:border-accent"
        type="text"
        name="search"
        value={searchTerm}
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchBox;
