import React from 'react';

interface LogoProps {
  classes?: string;
  height: string;
  width: string;
}

const Logo: React.FC<LogoProps> = ({ classes, height, width }) => {
  return (
    <div className={classes}>
      <svg
        height={height}
        viewBox="0 0 192 44"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <g fill="#fff" fillRule="nonzero" transform="translate(50 2)">
            <path d="m19.0042361 7.21321444c-3.0348173.02752726-5.4769423 2.54624938-5.4634734 5.63477746.0135807 3.088528 2.4776675 5.5849997 5.5126054 5.5849997s5.4990246-2.4964717 5.5126053-5.5849997c.0134689-3.08852808-2.428656-5.6072502-5.4634734-5.63477746zm0 9.90999996c-1.6979091.012121-3.2356276-1.0183451-3.8954913-2.6104724-.6598637-1.5921272-.3117823-3.4320324.881792-4.6610185 1.1935744-1.22898609 2.997332-1.60476205 4.5694462-.95195052 1.5721142.65281152 2.6026473 2.20551062 2.610642 3.93344142.0328241 2.3213683-1.7871336 4.2316388-4.068125 4.27z" />
            <path d="m45.6042708 16.4932144-3.9305555-8.99999996h-1.5427431l4.7461459 10.82999996-.2751389.66c-.579757 1.4-1.5329167 2.4-2.9479167 2.24v1.32c1.9063194.15 3.4785417-1.25 4.2843056-3.32l4.5889236-11.77999996h-1.4837848z" />
            <path d="m5.59121528 7.21321444c-1.61561285-.0083429-3.15465653.69993132-4.21552084 1.94v-6h-1.37569444l.04913194 9.99999996c.18198737 3.0774358 2.75072683 5.4406001 5.7777036 5.3153163 3.02697677-.1252837 5.39865936-2.6929274 5.33447596-5.775239-.0641833-3.08231156-2.54062156-5.54540348-5.57009622-5.54007726zm0 9.91004766c-2.31727666.010972-4.20458336-1.891799-4.21547559-4.2500169-.01079833-2.3582178 1.85892465-4.2788831 4.17620115-4.28998516 2.3172765-.01100621 4.20461054 1.89173666 4.21548972 4.24995436.04397841 2.3138357-1.75549458 4.229965-4.02881945 4.2900477z" />
            <path d="m36.6032986 9.15321444c-2.0038188-2.36461527-5.5108044-2.63112861-7.8375014-.59561085-2.3266969 2.03551781-2.594073 5.60404271-.5975389 7.97503091 1.9965342 2.3709883 5.5026827 2.6486744 7.8356306.6205799 1.1461867-.9719275 1.8554534-2.3758243 1.9652778-3.89v-9.99999996h-1.3756945zm-4.2155208 7.99999996c-1.6979091.012121-3.2356276-1.0183451-3.8954913-2.6104724-.6598637-1.5921272-.3117824-3.4320324.881792-4.6610185 1.1935744-1.22898609 2.997332-1.60476205 4.5694462-.95195052 1.5721142.65281152 2.6026473 2.20551062 2.610642 3.93344142.0183956 1.1139541-.3988749 2.1897046-1.1599859 2.9905191-.7611111.8008144-1.8036931 1.2610697-2.8983127 1.2794809z" />
            <path d="m75.8498958 2.65321444c-.6731303.01105092-1.2120881.5714562-1.2084864 1.25655851.003638.6851023.5484864 1.23957701 1.2216946 1.24327924.6732081.00366534 1.2238842-.54481468 1.2347432-1.22983775-.0053465-.6991355-.5609536-1.26455901-1.2479514-1.27z" />
            <path d="m74.64125 7.663214h2.112674v10.58h-2.112674z" />
            <path d="m85.1456597 7.33321444c-2.808925.00550252-5.0846624 2.32144731-5.0900694 5.17999996l.0491319 5.57h1.9652778v-5.58c-.0351063-1.0968053.5074342-2.1293552 1.4232517-2.70869795.9158175-.57934279 2.0657775-.61746258 3.0167014-.1.9509238.51746255 1.5583434 1.51189265 1.5934497 2.60869795v.2l.0687847 5.57h2.0733681v-5.56c-.0054196-2.8624518-2.2871342-5.17999996-5.0998959-5.17999996z" />
            <path d="m65.7287153 7.33312739c-1.6204535.00082668-3.1445073.7837379-4.1074306 2.11008705-1.3054864-1.79068046-3.5904857-2.53028265-5.6725854-1.83608662-2.0820998.69419604-3.4940698 2.66641038-3.5052618 4.89608658v5.57h2.0242361l.0687847-5.57c-.0542696-1.6955141 1.2523594-3.11477149 2.9184375-3.16999996 1.6660782-.05522848 3.0606957 1.27448586 3.1149653 2.96999996v.2 5.58h2.1618056v-5.58c-.0542697-1.6955141 1.2523593-3.11477149 2.9184375-3.16999996 1.6660781-.05522848 3.0606956 1.27448586 3.1149652 2.96999996v.2l.0687848 5.57h2.0242361v-5.57c-.0051962-1.3782178-.5488507-2.69773579-1.511083-3.66758904s-2.2640143-1.5103877-3.618292-1.50249797z" />
            <path d="m101.447639 9.13321444-.501146-.55c-.686828-.71714795-1.6529009-1.08399156-2.6334722-1-2.9305535.00552794-5.301834 2.42766366-5.2964147 5.40999376.0054379 2.9823302 2.38552 5.3955088 5.3160735 5.3899972 2.9305534-.0055306 5.3018394-2.4276608 5.2964274-5.409991v-10.52999996h-2.220774zm-3.1247918 6.99999996c-1.7311892.0055142-3.1389895-1.418214-3.1444293-3.1799896-.0054084-1.7617756 1.3935977-3.19445336 3.1247869-3.19999508 1.7311892-.00550966 3.1389982 1.41820908 3.1444342 3.17998468.007867.8485744-.317875 1.6651482-.904742 2.2680181-.5868672.6028699-1.3861705.9419819-2.2200498.9419819z" />
            <path d="m116.432882 1.71321444c-.892605.01616087-1.610868.7515435-1.621354 1.66.065852.85887796.769909 1.52164502 1.616441 1.52164502s1.550588-.66276706 1.616441-1.52164502c-.010333-.9046575-.722767-1.6385202-1.611528-1.66z" />
            <path d="m115.135799 7.343214h2.623646v10.34h-2.623646z" />
            <path d="m130.307743 2.83321444v-2.61c-2.879132-.73-5.080243.29-5.66 3.66l-.609236 3.67h-2.348507v2.53999996h1.965278l-1.365868 8.16c-.235834 1.55-1.532917 2-3.056007 1.46v2.62c2.879132.73 5.090069-.3 5.66-3.67l1.444479-8.57h2.515556v-2.53999996h-2.112674l.560104-3.25c.206354-1.57 1.473958-2.03 3.006875-1.47z" />
            <path d="m141.273993 12.5132144c.083527-1.9647353-.898869-3.81772011-2.558304-4.82544952-1.659436-1.00772942-3.728365-1.00772942-5.387801 0-1.659436 1.00772941-2.641831 2.86071422-2.558305 4.82544952-.044817 1.4969601.533626 2.9435741 1.592787 3.9833609s2.500541 1.5760493 3.968949 1.4766391c1.753141.0802259 3.424903-.7572205 4.431702-2.22l-2.112674-1.24c-.563153.6737342-1.401774 1.0431878-2.269896 1-1.294972.169306-2.520623-.6382714-2.898784-1.91h7.64493c.078283-.3586303.12758-.7231816.147396-1.09zm-7.802153-1c.245193-1.276256 1.388523-2.16500394 2.662952-2.06999996 1.232775-.04782024 2.309194.84222246 2.515555 2.07999996z" />
            <path d="m109.682153 13.9332144v-11.48999996h-2.61382v11.50999996c-.075146 1.2583248.472706 2.4716 1.459911 3.2331218.987204.7615218 2.283098.9704984 3.453284.5568782v-2.3c-.776285.51-2.034063.05-2.299375-1.51z" />
            <path d="m4.548 36.18c.864 0 1.609-.188 2.235-.564s1.108-.902 1.446-1.578.507-1.462.507-2.358-.169-1.682-.507-2.358-.82-1.202-1.446-1.578-1.371-.564-2.235-.564-1.609.188-2.235.564-1.108.902-1.446 1.578-.507 1.462-.507 2.358.169 1.682.507 2.358.82 1.202 1.446 1.578 1.371.564 2.235.564zm0-1.536c-.548-.008-1.004-.134-1.368-.378s-.637-.586-.819-1.026-.273-.96-.273-1.56.091-1.124.273-1.572.455-.794.819-1.038.82-.362 1.368-.354 1.004.134 1.368.378.637.586.819 1.026.273.96.273 1.56-.091 1.124-.273 1.572-.455.794-.819 1.038-.82.362-1.368.354zm7.4132308 1.356v-5.76l3.756 5.76h1.656v-8.64h-1.656v5.76l-3.756-5.76h-1.656v8.64zm12.9332307 0v-1.524h-3.72v-7.116h-1.632v8.64zm3.1412308 0v-8.64h-1.632v8.64zm3.8252308 0v-5.76l3.756 5.76h1.656v-8.64h-1.656v5.76l-3.756-5.76h-1.656v8.64zm13.1012307 0v-1.524h-4.008v-2.22h3.288v-1.524h-3.288v-1.848h4.008v-1.524h-5.64v8.64z" />
          </g>
          <g transform="translate(0 .5)">
            <path
              d="m21.05495 9.61383362c-6.30165 0-11.4294 5.12179438-11.4294 11.41719918 0 6.294331 5.12775 11.4161254 11.4294 11.4161254 6.302725 0 11.430475-5.1217944 11.430475-11.4161254 0-6.2954048-5.12775-11.41719918-11.430475-11.41719918m0 24.16907148c-7.0391 0-12.7667-5.719874-12.7667-12.7518723 0-7.0319982 5.7276-12.75294598 12.7667-12.75294598 7.040175 0 12.767775 5.72094778 12.767775 12.75294598 0 7.0319983-5.7276 12.7518723-12.767775 12.7518723"
              fill="#33706A"
            />
            <path
              d="m21.05495 4.89523287c-8.906375 0-16.15295 7.23815853-16.15295 16.13526303 0 8.8971046 7.246575 16.1363369 16.15295 16.1363369 8.90745 0 16.1551-7.2392323 16.1551-16.1363369 0-8.8971045-7.24765-16.13526303-16.1551-16.13526303m0 33.19502613c-9.417 0-17.0796-7.6526266-17.0796-17.0597631 0-9.4060627 7.6626-17.05976303 17.0796-17.05976303 9.41915 0 17.080675 7.65370033 17.080675 17.05976303 0 9.4071365-7.661525 17.0597631-17.080675 17.0597631"
              fill="#33706A"
            />
            <g fill="#33706A">
              <path d="m21.05495.71189721c-11.21655 0-20.34115 9.11400233-20.34115 20.31859869 0 11.2024489 9.1246 20.3185988 20.34115 20.3185988 11.217625 0 20.342225-9.1161499 20.342225-20.3185988 0-11.20459636-9.1246-20.31859869-20.342225-20.31859869m0 41.34909469c-11.608925 0-21.05495-9.4339803-21.05495-21.030496 0-11.59651564 9.446025-21.0304959 21.05495-21.0304959 11.61 0 21.05495 9.43398026 21.05495 21.0304959 0 11.5965157-9.44495 21.030496-21.05495 21.030496" />
              <path d="m17.6812439 16.1317028v9.7975863l9.3563232-5.2663361z" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Logo;
