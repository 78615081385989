import { FiltersSection } from '../../../../domain/filters';

const section: FiltersSection = {
  title: 'Duration',
  type: 'buttonGroup',
  filterKey: 'duration',
  cols: 3,
  isRadio: false,
  options: [
    { label: '10 min', value: 10 },
    { label: '20 min', value: 20 },
    { label: '30 min', value: 30 },
    { label: '45 min', value: 45 },
    { label: '60 min', value: 60 },
    { label: '90 min', value: 90 },
  ],
};

export default section;
