import Link from 'next/link';
import { useRouter } from 'next/router';
import { homepageRoute, routes } from '../../../domain/websiteDomain';
import Button from '../../common/button';

interface LoginProps {}

const LoginSection: React.FC<LoginProps> = () => {
  const router = useRouter();
  // if session view then set `target` on login to redirect back to same page on success
  const login = `/user/login?target=${router.asPath}`;

  return (
    <div className="flex-row items-center hidden sm:flex font-family-semibold space-x-24px mr-24px">
      <div data-cy="login-button">
        <Link href={login}>
          <a className="cursor-pointer hover:text-accent-hover">Login</a>
        </Link>
      </div>

      <Link href={homepageRoute}>
        <a className="cursor-pointer hover:text-accent-hover">Find a class</a>
      </Link>

      <a
        href={routes.MARKETING_TEACHERS}
        className="cursor-pointer hover:text-accent-hover"
      >
        Our teachers
      </a>

      <Button buttonStyle="primary" size="small" href={routes.SIGNUP}>
        Start your free trial
      </Button>
    </div>
  );
};

export default LoginSection;
