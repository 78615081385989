export const getDurationRange = (duration: number) => {
  switch (duration) {
    case 10:
      return { min: 0, max: 15 };
    case 20:
      return { min: 15, max: 25 };
    case 30:
      return { min: 25, max: 35 };
    case 40:
      return { min: 35, max: 42.5 };
    case 45:
      return { min: 42.5, max: 47.5 };
    case 50:
      return { min: 47.5, max: 55 };
    case 60:
      return { min: 55, max: 75 };
    case 90:
      return { min: 75, max: 999999 };
    default:
      console.error('Duration not recognised', duration);
      return undefined;
  }
};

export const filterKeys = [
  'classification',
  'type',
  'duration',
  'teacher',
  'level',
  'intensity',
];

export const areFiltersActive = (filters: any) =>
  filters ? Object.keys(filters).length > 0 : false;

export interface FiltersSection {
  title: string;
  tooltip?: string;
  type:
    | 'buttonGroup'
    | 'circleButtonGroup'
    | 'checkbox'
    | 'teacherSelect'
    | 'search';
  filterKey: string;
  filterOptionsBy?: 'classification';
  isRadio?: boolean;
  isDisabled?: boolean;
  cols?: 3 | 4;
  options: {
    label?: string;
    value: any;
    classification?: string;
    // classification?: Classification;
  }[];
}
