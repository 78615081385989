import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { gql } from 'graphql-request';
import fields from '../fragments/teacher';

const generateTeacherCollectionQuery = (args = {}) => {
  let query = null;

  if (Object.keys(args).length > 0) {
    query = {
      teacherCollection: {
        __args: args,
        ...fields,
      },
    };
  } else {
    query = {
      teacherCollection: {
        ...fields,
      },
    };
  }

  const graphql_query = jsonToGraphQLQuery(query, { pretty: true });

  return gql`{
    ${graphql_query}
  }`;
};

export default generateTeacherCollectionQuery;
