import { ArrowRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Image from 'next/image';
import { config } from '../../../domain/siteConfig';
import { routes } from '../../../domain/websiteDomain';
import useUserWithRedirects from '../../../hooks/useUser';
import { ExternalLink } from '../../common/externalLink';
import NewsletterSignup from './newsletterSignup';

interface FooterItem {
  heading: string;
  href?: string;
  items?: { label: string; href: string }[];
}

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const user = useUserWithRedirects();

  const initialData: FooterItem[][] = [
    [
      {
        heading: 'Students',
        items: [
          { label: 'Explore classes', href: routes.HOME },
          {
            label: 'Explore teachers',
            href: routes.MARKETING_TEACHERS,
          },
          {
            label: 'Start your 7 day trial',
            href: routes.SIGNUP,
          },

          {
            label: 'The Expansion Project',
            href: routes.EXPANSION_PROJECT,
          },
          {
            label: 'Yoga for Beginners',
            href: routes.YOGA_FOR_BEGINNERS,
          },
          {
            label: 'Pilates for Beginners',
            href: routes.PILATES_FOR_BEGINNERS,
          },
          {
            label: 'Live Community Events',
            href: routes.LIVE_COMMUNITY_EVENTS,
          },
          {
            label: 'Gift Cards',
            href: routes.GIFT_CARDS,
          },
        ],
      },
    ],
    [
      {
        heading: 'BodyMindLife Online',
        href: routes.MARKETING_HOME,
        items: [
          {
            label: 'About',
            href: routes.ABOUT_US,
          },
          {
            label: 'Pricing',
            href: routes.PRICING,
          },
          { label: 'Contact', href: `mailto:${config.contact.email}` },
          {
            label: 'Terms & Conditions',
            href: routes.TERMS_AND_CONDITIONS,
          },
          {
            label: 'Privacy Policy',
            href: routes.PRIVACY_POLICY,
          },
          {
            label: 'Teams @ Work',
            href: routes.TEAMS_AT_WORK,
          },
          {
            label: 'Teach Online',
            href: routes.MARKETING_EDUCATOR,
          },
          {
            label: 'FAQs',
            href: routes.STUDENT_FAQ,
          },
        ],
      },
    ],
  ];

  const borderClasses = `border-white border-opacity-20`;

  return (
    <footer
      className={clsx(
        'bg-background-dark text-white pt-24px lg:pt-72px border-t',
        borderClasses
      )}
    >
      <div className="grid grid-flow-row divide-y divide-white lg:content lg:gap-24px lg:grid-flow-col lg:divide-x lg:divide-y-0 divide-opacity-20 lg:grid-cols-3">
        {initialData.map((col, colIndex) => (
          <ul
            key={`footer-col-${colIndex}`}
            className={clsx(
              'content lg:px-0 lg:space-y-24px',
              colIndex > 0 && 'lg:pl-24px'
            )}
          >
            {col.map(item => (
              <li key={`footer-${item.href}`}>
                <div
                  className={clsx(
                    'relative flex items-center justify-center',
                    'flex-row py-24px',
                    'sm:justify-between',
                    'lg:pt-0'
                  )}
                >
                  {item.href ? (
                    <a href={item.href}>
                      <span className="font-family-bold">{item.heading}</span>
                    </a>
                  ) : (
                    <span className="font-family-bold">{item.heading}</span>
                  )}
                  <ArrowRightIcon className="absolute right-0 h-24px w-24px" />
                </div>

                {item.items?.length && (
                  <ul
                    className={clsx(
                      'flex',
                      'flex-col items-center space-x-0 space-y-24px pb-32px',
                      'sm:flex-row sm:space-x-48px sm:space-y-0',
                      'lg:flex-col lg:items-start lg:space-x-0 lg:space-y-24px lg:pb-0'
                    )}
                  >
                    {item.items.map(subItem => (
                      <li key={`footer-${subItem.href}`}>
                        <a href={subItem.href}>{subItem.label}</a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        ))}

        {!user.isLoggedIn && (
          <div className="pt-24px content lg:px-0 lg:pt-0 lg:pl-24px">
            <NewsletterSignup />
          </div>
        )}
      </div>

      <div
        className={clsx(
          'flex flex-col items-center justify-between text-sm border-t',
          borderClasses,
          !user.isLoggedIn ? 'mt-32px' : 'lg:mt-32px',
          'py-32px space-y-32px',
          'sm:flex-row sm:h-88px sm:py-0 sm:space-y-0'
        )}
      >
        <ul className="flex flex-row items-center content space-x-16px">
          {[
            {
              icon: '/svg/facebook.svg',
              href: config.facebook.url,
              alt: 'Facebook',
            },
            {
              icon: '/svg/instagram.svg',
              href: config.instagram.url,
              alt: 'Instagram',
            },
            {
              icon: '/pledge-one-percent.png',
              href: 'https://pledge1percent.org/',
              alt: 'Pledge 1%',
            },
          ].map(item => (
            <li key={`footer-social-${item.href}`}>
              <ExternalLink href={item.href} aria-label={item.alt}>
                <Image
                  src={item.icon}
                  width="24px"
                  height="24px"
                  alt={item.alt}
                />
              </ExternalLink>
            </li>
          ))}
        </ul>
        <div className="content">
          © Super 44 Tech Pty Ltd {dayjs().format('YYYY')}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
