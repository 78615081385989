import { CustomError } from 'ts-custom-error';
import { apiAdapter } from '../adapters/apiAdapter';
import {
  ContentfulClassCategories,
  ContentfulQueryGeneratorParams,
} from '../adapters/backendDTOs';
import {
  contentfulAdapter,
  ContentfulAdapterError,
} from '../adapters/contentfulAdapter';
import { loggingAdapter } from '../adapters/loggingAdapter';
import {
  classCategoriesDTOToClassCategories,
  featureSessionsDTOToSessions,
  sessionsDTOToSession,
  sessionsDTOToSessions,
} from '../adapters/mappers/contentfulMappers';
import { sessionCheckDTOToSessionCheck } from '../adapters/mappers/sessionMappers';
import {
  ApiService,
  ContentfulService,
  LoggingService,
} from '../adapters/ports';
import { getSlideImage, SessionVideoCheck } from '../domain/class';
import { ClassFragment } from '../typescript/generated/codegen';

export class SessionApplicationError extends CustomError {
  public constructor(public type: string, message?: string) {
    super(message);
  }
}

export const _sessionApplicationErrorFactory = (
  options: { type: string; caller: string; message: string; err: Error },
  defaultDeps: { logging: LoggingService }
): SessionApplicationError => {
  const { logging } = defaultDeps;
  const { caller, message, err, type } = options;

  const error = new SessionApplicationError(type, message);
  logging.error({
    caller: caller,
    message: `${message}: ${err}`,
  });
  logging.error({
    caller: `application.${caller}`,
    message: `Returning application layer error: ${error}`,
  });

  return error;
};

export const _getFeatureClasses = async (
  { filters, where, order, limit }: ContentfulQueryGeneratorParams,
  deps: {
    contentful: ContentfulService;
    logging: LoggingService;
  }
): Promise<ClassFragment[]> => {
  const { contentful } = deps;

  try {
    const s = await contentful.getFeatureClasses({
      filters,
      where,
      order,
      limit,
    });
    return featureSessionsDTOToSessions(s);
  } catch (err) {
    throw _sessionApplicationErrorFactory(
      {
        type: 'getFeatureClasses',
        caller: '_getFeatureClasses',
        message: 'Error getting feature classes',
        err: err as ContentfulAdapterError,
      },
      { logging: deps.logging }
    );
  }
};

export const _getClasses = async (
  contentfulQuery: string,
  deps: {
    contentful: ContentfulService;
    logging: LoggingService;
  }
): Promise<ClassFragment[]> => {
  const { contentful } = deps;
  try {
    const s = await contentful.getClasses(contentfulQuery);
    return sessionsDTOToSessions(s).filter(
      session => session.teacher && getSlideImage(session)
    );
  } catch (err) {
    throw _sessionApplicationErrorFactory(
      {
        type: 'getClasses',
        caller: '_getClasses',
        message: 'Error getting classes',
        err: err as ContentfulAdapterError,
      },
      { logging: deps.logging }
    );
  }
};

export const _getClassById = async (
  id: string,
  deps: {
    contentful: ContentfulService;
    logging: LoggingService;
  }
): Promise<ClassFragment> => {
  const { contentful } = deps;
  try {
    const s = await contentful.getClassById(id);
    return sessionsDTOToSession(s);
  } catch (err) {
    throw _sessionApplicationErrorFactory(
      {
        type: 'getClassById',
        caller: '_getClassById',
        message: 'Error getting class by id',
        err: err as ContentfulAdapterError,
      },
      { logging: deps.logging }
    );
  }
};

export const _getClassClassifications = async (deps: {
  contentful: ContentfulService;
  logging: LoggingService;
}): Promise<ContentfulClassCategories[]> => {
  const { contentful } = deps;
  try {
    const c = await contentful.getClassClassifications();
    return classCategoriesDTOToClassCategories(c);
  } catch (err) {
    throw _sessionApplicationErrorFactory(
      {
        type: 'getClassClassifications',
        caller: '_getClassClassifications',
        message: 'Error getting class classifications',
        err: err as ContentfulAdapterError,
      },
      { logging: deps.logging }
    );
  }
};

export const _checkVideoStatus = async (
  sessionId: string,
  deps: { api: ApiService; logging: LoggingService }
): Promise<SessionVideoCheck> => {
  const { api, logging } = deps;

  logging.debug({
    caller: '_checkVideoStatus',
    message: `checking video status for session with id: ${sessionId}`,
  });

  try {
    const sessionCheckRes = await api.checkVideoSession(sessionId);
    return sessionCheckDTOToSessionCheck(sessionCheckRes);
  } catch (err) {
    const errorCast = err as Error;

    throw _sessionApplicationErrorFactory(
      {
        type: 'CheckVideoStatusApplicationError',
        caller: '_checkVideoStatus',
        message: `Failed to check the status of video for session with id: ${sessionId}`,
        err: errorCast,
      },
      { logging }
    );
  }
};

export function sessionService() {
  const api: ApiService = apiAdapter();
  const logging: LoggingService = loggingAdapter();
  const contentful: ContentfulService = contentfulAdapter();

  return {
    checkVideoStatus: (sessionId: string): Promise<SessionVideoCheck> =>
      _checkVideoStatus(sessionId, { api, logging }),
    getFeatureClasses: ({
      filters,
      where,
      order,
      limit,
    }: ContentfulQueryGeneratorParams) =>
      _getFeatureClasses(
        {
          filters,
          where,
          order,
          limit,
        },
        {
          contentful,
          logging,
        }
      ),
    getClasses: (contentfulQuery: string) =>
      _getClasses(contentfulQuery, { contentful, logging }),
    getClassById: (id: string) => _getClassById(id, { contentful, logging }),
    getClassClassifications: () =>
      _getClassClassifications({ contentful, logging }),
  };
}
