import clsx from 'clsx';
import React from 'react';
import { FiltersSection } from '../../../domain/filters';
import { TW_RING } from '../../../domain/websiteDomain';

type Value = any;

interface CheckboxSectionProps {
  section: FiltersSection;
  allValues: { [key: string]: any[] };
  setValues: (newValues: any) => void;
}

const CheckboxSection: React.FC<CheckboxSectionProps> = ({
  section,
  allValues,
  setValues,
}) => {
  const { filterKey, isRadio, options, filterOptionsBy } = section;

  const values = allValues[filterKey] || [];

  const handleOnClick = (value: Value) => {
    let newValues: any = {};

    if (values.includes(value)) {
      newValues = isRadio
        ? {
            ...allValues,
            [filterKey]: [],
          }
        : {
            ...allValues,
            [filterKey]: values.filter(v => v !== value),
          };
    } else {
      newValues = isRadio
        ? {
            ...allValues,
            [filterKey]: [value],
          }
        : {
            ...allValues,
            [filterKey]: [...values, value],
          };
    }

    // when selecting 'classification', clear all selected in 'type'
    if (filterKey === 'classification' && newValues?.type) newValues.type = [];

    setValues(newValues);
  };

  const isOptionDisabled = (option: FiltersSection['options'][0]) => {
    if (section.isDisabled) return true;

    return (
      filterOptionsBy &&
      allValues[filterOptionsBy] &&
      option[filterOptionsBy] &&
      !allValues[filterOptionsBy].includes(option[filterOptionsBy])
    );
  };

  return (
    <div className="grid grid-cols-2 gap-6">
      {options
        .filter(option => !isOptionDisabled(option))
        .map(option => {
          const id = `checkbox-${option.classification}-${option.value}`;
          const isSelected = values.includes(option.value);

          return (
            <div
              key={`${section.type}-${filterKey}-${option.value}-${option.classification}`}
              className={clsx('flex flex-row items-center')}
            >
              <input
                data-cy={`input-${id}`}
                id={id}
                type="checkbox"
                className={clsx(
                  'text-accent-light cursor-pointer form-checkbox h-24px w-24px rounded-sm border-accent-light',
                  TW_RING
                )}
                checked={isSelected}
                onChange={() => handleOnClick(option.value)}
              />
              <label
                data-cy={`label-${id}`}
                htmlFor={id}
                className="ml-4 cursor-pointer"
              >
                <div className="font-family-semibold">{option.value}</div>
                {filterOptionsBy &&
                  allValues[filterOptionsBy] &&
                  allValues[filterOptionsBy].length > 1 && (
                    <div className="text-xs text-gray-400">
                      {option.classification}
                    </div>
                  )}
              </label>
            </div>
          );
          // return (
          //   <Checkbox
          //     key={`${section.type}-${filterKey}-${option.value}-${option.classification}`}
          //     handleSelect={() => handleOnClick(option.value)}
          //     isSelected={isSelected}
          //     label={option.label || option.value}
          //     sublabel={option.classification}
          //     value={option.value}
          //     isDisabled={isOptionDisabled(option)}
          //   />
          // );
        })}
    </div>
  );
};

export default CheckboxSection;
