import { User } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { SyntheticEvent, useContext } from 'react';
import { billingService } from '../../../application/billing';
import { AppContext, Types } from '../../../context';
import {
  showBMLToConvertAction,
  showSubscribeAction,
} from '../../../domain/user';
import { routes } from '../../../domain/websiteDomain';
import useUserWithRedirects from '../../../hooks/useUser';
import Button from '../../common/button';
import { Dropdown, DropdownItem, DropdownSection } from '../../common/dropdown';
import ProfileIcon from '../../icons/profile';
import ProfileLogout from '../../userProfile/ProfileLogout';

const UserSection = () => {
  const user = useUserWithRedirects();
  const router = useRouter();
  const { dispatch } = useContext(AppContext);

  const handleLogout = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch({ type: Types.LOGOUT });
    dispatch({ type: Types.RESET_FILTERS });
    dispatch({ type: Types.SET_RECOMMENDATIONS, payload: [] });
    setTimeout(() => router.push('/'), 100);
  };

  let action: {
    label: string;
    href?: string;
    onClick?: (e: SyntheticEvent) => void;
  } = {
    label: '',
    href: undefined,
    onClick: undefined,
  };

  const handleGotoBilling = () => {
    const applicationBilling = billingService();

    applicationBilling.goToStripeBillingPortal();
  };

  // only show one action button at a time...
  if (showBMLToConvertAction(user, router)) {
    action = {
      label: 'Convert Your Membership',
      href: routes.SUBSCRIBE,
    };
  } else if (showSubscribeAction(user, router)) {
    action = {
      label: 'Activate Subscription',
      href: routes.SUBSCRIBE,
    };
  } else if (user.billingStatus === 'cancelling') {
    action = {
      label: 'Reactivate Subscription',
      onClick: () => handleGotoBilling(),
    };
  } else if (
    user.billingStatus === 'trialing' &&
    user.status === 'active' &&
    !user.BMLRecord
  ) {
    action = {
      label: 'Update Subscription',
      href: routes.SUBSCRIBE,
    };
  }

  return (
    <div className="flex-row items-center hidden sm:flex font-family-semibold space-x-24px">
      {action.label && (
        <div data-cy="navbar-action-container">
          <Button
            buttonStyle={'primary'}
            href={action.href}
            onClick={action.onClick}
            size="small"
          >
            {action.label}
          </Button>
        </div>
      )}

      <a
        href={routes.MARKETING_TEACHERS}
        className="cursor-pointer hover:text-accent-hover"
      >
        Our teachers
      </a>

      <Dropdown header={<UserProfile user={user} />}>
        <DropdownSection>
          <DropdownItem onClick={() => router.push(routes.USER_PROFILE)}>
            Profile
          </DropdownItem>
        </DropdownSection>
        <DropdownSection>
          <DropdownItem onClick={handleLogout}>
            <ProfileLogout />
          </DropdownItem>
        </DropdownSection>
      </Dropdown>
    </div>
  );
};

interface UserProfileProps {
  user: User;
}

const UserProfile: React.FC<UserProfileProps> = ({ user }) => (
  <button
    className="flex items-center transition-colors hover:text-accent-hover"
    id="profile-button"
  >
    <div className="w-10 h-10 mr-2">
      <ProfileIcon />
    </div>
    <span className="pr-2">{user.firstName}</span>
  </button>
);

export default UserSection;
