import { loadStripe } from '@stripe/stripe-js';
import { CustomError } from 'ts-custom-error';
import { StripeService } from './ports';

export class StripeAdapterError extends CustomError {
  public constructor(message?: string, public data?: unknown) {
    super(message);
  }
}

const redirectToCheckout = async (sessionID: string): Promise<void> => {
  try {
    const stripe = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!);

    await stripe?.redirectToCheckout({ sessionId: sessionID });
  } catch (err) {
    const stripeError = err as Error;

    throw new StripeAdapterError(stripeError.message, stripeError.name);
  }
};

export const stripeService = (): StripeService => {
  return {
    redirectToCheckout,
  };
};
