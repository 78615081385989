import clsx from 'clsx';
import React from 'react';
import LoadingSpinner from '../../common/loadingSpinner';

interface FetchingMessageProps {
  className?: string;
}

const FetchingMessage: React.FC<FetchingMessageProps> = ({ className }) => {
  return (
    <span className={clsx('text-sm flex items-center', className)}>
      <LoadingSpinner color="white" /> Fetching your details, please wait...
    </span>
  );
};

export default FetchingMessage;
