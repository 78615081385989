import { TeacherFragment } from '../../../../typescript/generated/codegen';

const section = (teachers?: TeacherFragment[]) => ({
  title: 'Teachers',
  type: 'teacherSelect',
  filterKey: 'teacher',
  options: teachers
    ? teachers
        .map((teacher: TeacherFragment) => ({
          label: `${teacher.firstName} ${teacher.lastName}`,
          value: teacher.beid,
          teacher,
        }))
        .sort((a: any, b: any) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        })
    : [],
});

export default section;
